import { Outlet, Navigate, useLocation } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import { Roles } from '../../_helpers';

interface Props {
  roles: Array<Roles>,
  redirectTo: string
}

const PrivateRoute: React.FC<Props> = ({ roles, redirectTo }) => {
  const location = useLocation();
  const account = JSON.parse(localStorage.getItem("account") as any);
  // not logged in so redirect to login page with the return url
  if(!account){
    return <Navigate to="/login" state={{ from: window.location.pathname }} replace /> 
  } 

  const auth: { sub: string; role: Roles } = jwt_decode(account.token);
  // check if route is restricted by role and redirect to 404 page
  if(roles && roles.indexOf(auth.role) === -1) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />
  }
  return <Outlet />
}

export { PrivateRoute }

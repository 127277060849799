import { axios } from "./axios";
import { useAuth } from "./useAuth";

export const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.post('/account/refresh-token', { 
      refreshToken: auth?.refreshToken
    }, {
      withCredentials: true
    });
    setAuth((prev: any) => {
      return { 
        ...prev,
        token: response.data.token,
        refreshToken: response.data.refreshToken
      }
    });
    return response.data.token;
  }
  return refresh;
}
export const appConstants = {
  ADMIN_SIDEBAR: "ADMIN_SIDEBAR",
  ADMIN_THEME: "ADMIN_THEME",
  SELECT_LIST: "SELECT_LIST",
  LOAN_VIEW: "LOAN_VIEW",
  WITHDRAWAL_VIEW: "WITHDRAWAL_VIEW",
  MODIFYSAVINGS_VIEW: "MODIFYSAVINGS_VIEW",
  RESETPASSWORD_ALERT: "RESETPASSWORD_ALERT",

  PENDING_LOANS: "PENDING_LOANS",
  PENDING_WITHDRAWALS: "PENDING_WITHDRAWALS",
  PENDING_MODIFYSAVINGS: "PENDING_MODIFYSAVINGS",
};

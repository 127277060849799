import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useAuth } from '_helpers';

const HomeHeader = (props: { onCollapse?: () => void; mdShow: boolean, show: boolean }) =>  {
  const { auth } = useAuth();
  const { mdShow, onCollapse, show } = props;

  return (
    <>
      <Navbar expand={"lg"} className={classNames("fixed-top px-2 bg-gray-100 shadow-sm navbar-theme-primary ", {
        "d-md-none": mdShow
      })}>
        <Container className="max-width-lg d-flex">
          <Navbar.Brand className="me-lg-5 d-flex align-items-center" as={Link} to="/">
            <img src={process.env.PUBLIC_URL + "/assets/images/main/logo.png"} alt="logo" width="40" />
            <div className="ms-2 fw-bolder d-sm-block d-none">FUO Cooperative</div>
          </Navbar.Brand> 
          {auth?.id ? (
            <Navbar.Toggle onClick={onCollapse} className={`d-flex d-lg-none flex-column justify-content-around ${show ? "" : "hide"}`}>
              <span className="toggler-icon top-bar" />
              <span className="toggler-icon middle-bar" />
              <span className="toggler-icon bottom-bar" />
            </Navbar.Toggle>
          ) : (
            <div className="d-lg-flex d-non">
              <Link to="/login" className="btn btn-success py-2 fs-6"><i className="fa fa-sign-in me-2"></i>Login</Link>
            </div>
          )}
        </Container>
      </Navbar>
    </>
  );
}

export { HomeHeader }
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
// import DarkMode from '_components/DarkMode';
import { appConstants } from '_constants';
import { RootState } from '_reducers';
import { useAuth } from '_helpers';

const Topbar = () => {
  const { auth: account, logout } = useAuth();
  const dispatch = useDispatch();
  const { showSidebar } = useSelector((state: RootState) => state.app.set2zc35lk);

  return (
    <>
      <nav className={`navbar navbar-expand navbar-light topbar mb-4 static-top border-bottom ${showSidebar ? "nav-toggled " : ""}`}>
        <button id="sidebarToggleTop" onClick={() => dispatch({ type: appConstants.ADMIN_SIDEBAR, payload: !showSidebar })} className="btn btn-light d-md-none rounded-circle me-3">
          <i className="fa fa-bars"></i>
        </button>

        {/* <form className="d-none d-sm-inline-block form-inline navbar-search">
          <div className="input-group-merge">
            <input type="text" className="form-control" placeholder="Search for anything" readOnly />
            <i className="fa fa-search"></i>
          </div>
        </form> */}
        <div className="text-center d-none d-md-inline">
          <button className="bg-gray-300 rounded-circle border-0" id="sidebarToggle" onClick={() => {
            dispatch({ type: appConstants.ADMIN_SIDEBAR, payload: !showSidebar });
            document.body.classList.toggle("sidebar-icon-only");
          }}></button>
        </div>

        <ul className="navbar-nav ml-auto">
          {/* <Dropdown className="nav-item dropdown no-arrow d-sm-none">
            <Dropdown.Toggle className="nav-link dropdown-toggle">
              <i className="fa fa-search"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in">
              <form className="form-inline me-auto w-100 navbar-search">
                <div className="input-group">
                  <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </Dropdown.Menu>
          </Dropdown> */}
          
          {/* <li className="nav-item dropdown no-arrow mx-1 d-none d-md-block">
            <span className="nav-link">
              <DarkMode />
            </span>
          </li> */}

          {/* <Dropdown className="nav-item dropdown no-arrow mx-1">
            <Dropdown.Toggle className="nav-link dropdown-toggle">
              <i className="fa fa-bell"></i>
              <span className="badge bg-danger badge-counter">3+</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in">
              <h6 className="dropdown-header">Alerts Center</h6>
              <Dropdown.Item href="#demo" className="dropdown-item d-flex align-items-center">
                <div className="me-3">
                  <div className="icon-circle bg-primary">
                    <i className="fa fa-file text-white"></i>
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 12, 2019</div>
                  <span className="fw-bold">A new monthly report is ready to download!</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#demo" className="dropdown-item d-flex align-items-center">
                <div className="me-3">
                  <div className="icon-circle bg-success">
                    <i className="fa fa-usd text-white"></i>
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 7, 2019</div>
                  $290.29 has been deposited into your account!
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#demo" className="dropdown-item d-flex align-items-center">
                <div className="me-3">
                  <div className="icon-circle bg-warning">
                    <i className="fa fa-exclamation-triangle text-white"></i>
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 2, 2019</div>
                  Spending Alert: We've noticed unusually high spending for your account.
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Dropdown className="nav-item dropdown no-arrow mx-1">
            <Dropdown.Toggle className="nav-link dropdown-toggle" aria-expanded="false">
              <i className="fa fa-envelope"></i>
              <span className="badge bg-danger badge-counter">7</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in">
              <h6 className="dropdown-header">Message Center</h6>
              <Dropdown.Item href="#/action-1" className="dropdown-item d-flex align-items-center">
                <div className="dropdown-list-image me-3">
                  <img className="rounded-circle" src="https://source.unsplash.com/fn_BT9fwg_E/60x60" alt="" />
                  <div className="status-indicator bg-success"></div>
                </div>
                <div className="fw-bold">
                  <div className="text-truncate">Hi there! I am wondering if you can help me with a problem I've been having.</div>
                  <div className="small text-gray-500">Emily Fowler · 58m</div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1" className="dropdown-item d-flex align-items-center">
                <div className="dropdown-list-image me-3">
                  <img className="rounded-circle" src="https://source.unsplash.com/AU4VPcFN4LE/60x60" alt="" />
                  <div className="status-indicator"></div>
                </div>
                <div>
                  <div className="text-truncate">I have the photos that you ordered last month, how would you like them sent to you?</div>
                  <div className="small text-gray-500">Jae Chun · 1d</div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1" className="dropdown-item d-flex align-items-center">
                <div className="dropdown-list-image me-3">
                  <img className="rounded-circle" src="https://source.unsplash.com/CS2uCrpNzJY/60x60" alt="" />
                  <div className="status-indicator bg-warning"></div>
                </div>
                <div>
                  <div className="text-truncate">Last month's report looks great, I am very happy with the progress so far, keep up the good work!</div>
                  <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1" className="dropdown-item d-flex align-items-center">
                <div className="dropdown-list-image me-3">
                  <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60" alt="" />
                  <div className="status-indicator bg-success"></div>
                </div>
                <div>
                  <div className="text-truncate">Am I a good boy? The reason I ask is because someone told me that people say this to all dogs, even if they aren't good...</div>
                  <div className="small text-gray-500">Chicken the Dog · 2w</div>
                </div>
              </Dropdown.Item>
              <a className="dropdown-item text-center small text-gray-500" href="#demo">Read More Messages</a>
            </Dropdown.Menu>
          </Dropdown> */}

          <div className="topbar-divider d-none d-sm-block"></div>

          <Dropdown className="nav-item dropdown">
            <Dropdown.Toggle className="nav-link dropdown-toggle">
              <span className="me-2 d-none d-lg-inline text-gray-700 small text-capitalize">{account.surname +" "+account.othernames}</span>
              <img className="img-profile rounded-circle" 
                src={account.cooperatorInfo?.photo ? account.cooperatorInfo?.photo.url : process.env.PUBLIC_URL + "/assets/images/main/user.png"}
                alt=""
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu shadow" align="end">
              <Dropdown.Item href="/account" className="dropdown-item px-3 pe-5 text-muted fw-light">
                <i className="fa fa-user fa-fw me-2"></i>
                Membership
              </Dropdown.Item>
              <Dropdown.Item onClick={() => logout()} className="dropdown-item px-3 text-muted fw-light">
                <i className="fa fa-sign-out fa-fw me-2 text-danger"></i>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </nav>
    </>
  )
}

export { Topbar }
import _axios from 'axios';

const baseUrl: string = `${process.env.REACT_APP_API_URL}/api`;

export const axios = _axios.create({
  baseURL: baseUrl
});

export const axiosPrivate = _axios.create({
  baseURL: baseUrl,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});
import { appConstants } from '_constants'
import { FilterArgsType } from 'types';

let adminTheme = localStorage.getItem("adminTheme");
let selectlist = localStorage.getItem("selectlist");
let loanView = localStorage.getItem("loanView");
let withdrawalView = localStorage.getItem("withdrawalView");
let modifySavingsView = localStorage.getItem("modifySavingsView");

let initialState: { 
  set2zc35lk: {
    showSidebar: boolean;
    theme: string;
    selectlist: string,
  },
  loanFilter: FilterArgsType,
  withdrawalFilter: FilterArgsType,
  modifySavingsFilter: FilterArgsType,
  pending: {
    loan: number;
    withdrawal: number;
    modifySavings: number;
  }
} =  {
  set2zc35lk: {
    showSidebar: false,
    theme: adminTheme ? adminTheme : "dark",
    selectlist: selectlist ? selectlist : "",
  },
  loanFilter: loanView ? { status: loanView } : {},
  withdrawalFilter: withdrawalView ? { status: withdrawalView } : {},
  modifySavingsFilter: modifySavingsView ? { status: modifySavingsView } : {},
  pending: {
    loan: 0,
    withdrawal: 0,
    modifySavings: 0,
  },
};

export function appReducer(state = initialState, action: { type: string; payload: any } ) {
  switch (action.type) {
    case appConstants.ADMIN_SIDEBAR:
      return {
        ...state,
        set2zc35lk: {
          ...state.set2zc35lk,
          showSidebar: action.payload,
        }
      };
    case appConstants.ADMIN_THEME:
      return {
        ...state,
        set2zc35lk: {
          ...state.set2zc35lk,
          theme: action.payload,
        }
      };
    case appConstants.SELECT_LIST:
      return {
        ...state,
        set2zc35lk: {
          ...state.set2zc35lk,
          selectlist: action.payload,
        }
      };
      
    case appConstants.LOAN_VIEW:
      return {
        ...state,
        loanFilter: action.payload,
      };
    case appConstants.WITHDRAWAL_VIEW:
      return {
        ...state,
        withdrawalFilter: action.payload,
      };
    case appConstants.MODIFYSAVINGS_VIEW:
      return {
        ...state,
        modifySavingsFilter: action.payload
      };
    case appConstants.PENDING_LOANS:
      return {
        ...state,
        pending: {
          ...state.pending,
          loan: action.payload
        }
      };
    case appConstants.PENDING_WITHDRAWALS:
      return {
        ...state,
        pending: {
          ...state.pending,
          withdrawal: action.payload
        }
      };
    case appConstants.PENDING_MODIFYSAVINGS:
      return { 
        ...state,
        pending: {
          ...state.pending,
          modifySavings: action.payload
        }
      };
    default:
      return state;
  }
}
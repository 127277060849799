import { useEffect } from "react";
import { AxiosError, isAxiosError } from "axios";
import { axiosPrivate } from "./axios";
import { useAuth } from "./useAuth";
import { useRefreshToken } from "./useRefreshToken";

export const useAxiosPrivate = () => {
  const { auth, logout } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if(!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.token}`;
        }
        return config;
      }, 
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if(error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    }
  }, [auth, refresh]);

  function axiosErrorHandler(err: AxiosError | unknown) {
    let errMessage: { message: string, type?: "success" | "error" | "info" | "warn"; title?: string, color?: string };
    if(isAxiosError(err)) {
      if(err?.response) {
        if(err.response.status === 401) {
          logout();
        }
        errMessage = err.response.data;
        // console.log(err.response.data, err.response.data)
      } else if(err?.request) {
        errMessage = { message: "No internet connection, try again" }
      } else {
        errMessage = { message: "Error: request could not be made" }
      }
      return errMessage;
    }
    return { message: "Axios Error didn't occur!" }
  }

  return { axiosPrivate, axiosErrorHandler }
}
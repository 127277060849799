import { useQuery } from 'react-query';
import { useAuth, useAxiosPrivate } from '_helpers';

export function useWhoAmI() {
  const { axiosPrivate, axiosErrorHandler } = useAxiosPrivate();
  const { auth, update } = useAuth();

  const { data } = useQuery({ 
    queryKey: ["who-am-i", auth?.id], 
    queryFn: async ({ queryKey }) => {
      const { data } = await axiosPrivate.get(`/account/who-am-i/${queryKey[1]}`);
      return data;
    },
    onSuccess(data) {
      const accountCloneObj = Object.assign({}, data);
      update(accountCloneObj);
    },
    onError(err) {
      axiosErrorHandler(err);
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!auth.id
  });

  return { data };
}

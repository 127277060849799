import React, { useState } from 'react';
import { Outlet } from 'react-router-dom'
import { HomeHeader } from '_layouts/home/header';
import { Sidebar } from './sidebar'
import { Header } from './header'
import { useWhoAmI } from 'hooks/useWhoAmI';

const ProfileLayout: React.FC = () => {
  useWhoAmI();
  const [show, setShow] = useState(false);
  const onCollapse = () => setShow(!show);

  return (
    <>
      <HomeHeader mdShow={true} show={show} onCollapse={onCollapse} />
      <Sidebar show={show} onCollapse={onCollapse} />
      <div className="profile-content">
        <div className="profile-content-wrapper">
          <Header />
          <Outlet />
          {/* <Footer /> */}
        </div>
      </div>
    </>
  )
}

export { ProfileLayout }
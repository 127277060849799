import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom';
// import io, { Socket } from 'socket.io-client';
import { history, Roles, useAuth } from '_helpers'
import { PrivateRoute } from '_components/PrivateRoute';
import { CustomRouter } from '_components/CustomRouter';
import { DashboardLayout } from '_layouts/admin';
import { ProfileLayout } from '_layouts/profile'
import { HomeLayout } from '_layouts/home';
import Dash404 from '_layouts/admin/404';
import Page404 from './Errors/Page404';
import Preloader from './_components/Preloader';

const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('./Account/Login'));
// Cooperators
const Account = lazy(() => import('Profile/Account'));
const Loan = lazy(() => import('Profile/Loan'));
const LoanHistoryDetails = lazy(() => import('Profile/LoanHistoryDetails'));
const Withdraw = lazy(() => import('Profile/Withdraw'));
// const WithdrawalHistory = lazy(() => import('Profile/sections/WithdrawalHistory'));
const CommodityLoan = lazy(() => import('Profile/CommodityLoan'));
const CommodityLoanDetails = lazy(() => import('Profile/CommodityLoanDetails'));
const AsGuarantor = lazy(() => import('Profile/AsGuarantor'));
const ProfileSettings = lazy(() => import('Profile/ProfileSettings'));
const EditProfile = lazy(() => import('Profile/settings/EditProfile'));
const ChangePwd = lazy(() => import('Profile/settings/ChangePwd'));
const ModifyAccountSavings = lazy(() => import('Profile/settings/ModifySavings'));
// Admin/Executive Routes
const Dashboard = lazy(() => import('./Dashboard'));
const CooperatorsList = lazy(() => import('./Cooperators/CooperatorsList'));
const NewCooperator = lazy(() => import('./Cooperators/AddCooperator'));
const MonthlyContributions = lazy(() => import('./Cooperators/MonthlyContributions'));
const ViewCooperator = lazy(() => import('./Cooperators/ViewCooperator'));
const CooperatorInformation = lazy(() => import('./Cooperators/sections/CooperatorInformation'));
const CooperatorContribution = lazy(() => import('./Cooperators/sections/ContributionRecord'));
const CooperatorLoanRecords = lazy(() => import('./Cooperators/sections/LoanRecords'));
const CooperatorWithdrawalRecords = lazy(() => import('./Cooperators/sections/WithdrawalRecord'));
const CooperatorModifySavings = lazy(() => import('./Cooperators/sections/IorDSavingsRecord'));
const Withdrawals = lazy(() => import('./Withdrawals'));
const MonthlyWithdrawals = lazy(() => import('./Withdrawals/MonthlyWithdrawals'));
const ModifySavings = lazy(() => import('./ModifySavings'));
const Cashflows = lazy(() => import('./Management/Cashflows'));
const CashflowDetail = lazy(() => import('./Management/CashflowPreview'));
const LoanMonthlyDeductions = lazy(() => import('./Loans/LoanMonthlyDeductions'));
const Settings = lazy(() => import('./Settings'));

const LoanApplications = lazy(() => import('./Loans/LoanApplications'));
const LoanPreview = lazy(() => import('./Loans/LoanPreview'));
const CommodityLoans = lazy(() => import('./Loans/CommodityLoans'));
const AddCommodity = lazy(() => import('./Loans/AddCommodity'));
const EditCommodityLoan = lazy(() => import('./Loans/EditCommodityLoan'));

function App() {
  const { isloadingAuth } = useAuth();
  // const [socket, setSocket] = useState<Socket | null>(null);
  // useEffect(() => {
  //   const newSocket = io("http://127.0.0.1:7000", {
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 5000,
  //     autoConnect: false
  //   });
  //   setSocket(newSocket);
  // }, []);
  // console.log([...Object.values(Roles)].filter(role => role !== Roles.Cooperator));
  if(isloadingAuth) {
    return <Preloader />
  }

  return (
    <CustomRouter history={history}>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
          {/* All Roles (authorized only) */}
          <Route element={<PrivateRoute roles={[...Object.values(Roles)]} redirectTo={"/404"} />}>
            <Route element={<ProfileLayout />}>
              <Route path="/account">
                <Route index element={<Account />} />
              </Route>
              <Route path="loan">
                <Route index element={<Loan />} />
                <Route path=":loanId" element={<LoanHistoryDetails />} />
              </Route>
              <Route path="/withdraw" element={<Withdraw />} />
              <Route path="/as-guarantor" element={<AsGuarantor />} />
              <Route path="/profile-settings" element={<ProfileSettings />}>
                <Route index element={<EditProfile />} />
                <Route path="update-pwd" element={<ChangePwd />} />
                <Route path="change-savings" element={<ModifyAccountSavings />} />
              </Route>
              <Route path="/commodity-loan">
                <Route index element={<CommodityLoan />} />
                <Route path=":slug" element={<CommodityLoanDetails />} />
              </Route>
            </Route>
          </Route>
          {/* Executives Only Roles */}
          <Route element={<PrivateRoute roles={[...Object.values(Roles)].filter(role => role !== Roles.Cooperator)} redirectTo={"/404"} />}>
            <Route element={<DashboardLayout />}>
              <Route path="/dashboard">
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="/cooperators">
                <Route index element={<CooperatorsList />} />
                <Route element={<PrivateRoute roles={[Roles.President, Roles.Secretary]} redirectTo={"404"} />}>
                  <Route path=":cooperatorId" element={<ViewCooperator />}>
                    <Route index element={<CooperatorInformation />} />
                    <Route path="loans" element={<CooperatorLoanRecords />} />
                    <Route path="withdrawals" element={<CooperatorWithdrawalRecords />} />
                    <Route path="contributions" element={<CooperatorContribution />} />
                    <Route path="modify-savings" element={<CooperatorModifySavings />} />
                  </Route>
                </Route>
                <Route path="contribution-enumeration" element={<MonthlyContributions />} />
                <Route path="add" element={<NewCooperator />} />
                <Route path="*" element={<Dash404 />} />
              </Route>
              <Route path="/loans">
                <Route index element={<LoanApplications />} />
                <Route path="preview/:id" element={<LoanPreview />} />
                <Route path="enumerations" element={<LoanMonthlyDeductions />} />
                <Route path="*" element={<Dash404 />} />
              </Route>
              <Route path="/withdrawals">
                <Route index element={<Withdrawals />} />
                <Route path="enumerations" element={<MonthlyWithdrawals />} />
              </Route>
              <Route path="/modify-savings" element={<ModifySavings />} />
              <Route path="/management">
                <Route index element={<Dash404 />} />
                <Route path="cashflow" element={<Cashflows />} />
                <Route path="cashflow/preview/:id" element={<CashflowDetail />} />
                <Route path="loan-deductions" element={<LoanMonthlyDeductions />} />
                <Route path="commodities" element={<CommodityLoans />} />
                <Route path="add-commodity" element={<AddCommodity />} />
                <Route path="commodities/edit/:slug" element={<EditCommodityLoan />} />
                <Route path="*" element={<Dash404 />} />
              </Route>
              <Route path="/settings" element={<Settings />} />
            </Route>
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </CustomRouter>
  );
}

export default App;

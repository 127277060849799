import { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import { useSelector } from 'react-redux';
import Collapse from 'react-bootstrap/Collapse'
import { RootState } from '_reducers';
import { Roles, useAuth } from '_helpers';

const Sidebar = (props: { loanPending: number, withdrawalPending: number, modifySavingsPending: number }) => {
  const {
    loanPending,
    withdrawalPending,
    modifySavingsPending,
  } = props;
  const { auth: account } = useAuth()
  const { pathname } = useLocation();
  const { showSidebar, theme } = useSelector((state: RootState) => state.app.set2zc35lk);
  const [password, setPassword] = useState<any>({});
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    onRouteChanged();

    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function() {
        if(body?.classList.contains('sidebar-icon-only')) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function() {
        if(body?.classList.contains('sidebar-icon-only')) {
          el.classList.remove("hover-open");
        }
      });
    });

    const mediaQuery = window.matchMedia("(max-width: 768px)");
    if(mediaQuery.matches) {
      document.body.classList.add("sidebar-icon-only");
    } else if(!showSidebar && windowSize.width > 768) {
      document.body.classList.remove("sidebar-icon-only");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, windowSize]);

  const toggleMenuState = (menuState: string) => {
    if(password[menuState]) {
      setPassword({[menuState] : false});
    } else if(Object.keys(password).length === 0) {
      setPassword({[menuState] : true});
    } else {
      Object.keys(password).forEach(i => {
        setPassword({[i]: false});
      });
      setPassword({[menuState] : true});
    }
  }
  
  const onRouteChanged = () => {
    Object.keys(password).forEach(i => {
      setPassword({[i]: false});
    });

    const dropdownPaths = [
      { path: `/cooperators`, state: 'cooperatorsOpen' },
      { path: `/loans`, state: 'loansOpen' },
      { path: `/withdrawals`, state: 'withdrawalsOpen' },
      { path: `/management`, state: 'managementOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if(isPathActive(obj.path)) {
        setPassword({[obj.state] : true});
      }
    }));

    // const { innerWidth: width, innerHeight: height } = window;
    // if(width > 767) {
      // dropdownPaths.forEach((obj => {
      //   if(isPathActive(obj.path)) {
      //     setPassword({[obj.state] : true});
      //   }
      // }));
    // } else {
    //   dropdownPaths.forEach((obj => {
    //     if(isPathActive(obj.path)) {
    //       setPassword({[obj.state] : false});
    //     }
    //   }));
    // }
  }

  const isPathActive = (path: string) => {
    return pathname.startsWith(path);
  }

  return (
    <>
      <ul className={`navbar-nav sidebar ${showSidebar ? "toggled " : ""} sidebar-${theme}`} id="sidebar">
        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
          <div className="sidebar-brand-icon rotate-n-15">
            {/* <img src={process.env.PUBLIC_URL + "/assets/images/main/logo.png"} alt="logo" /> */}
            <i className="fa fa-th-large"></i>
          </div>
          <div className="sidebar-brand-text mx-3">{
            account.role === Roles.AssistantSecretary ? "Assistant Secretary" 
            : account.role === Roles.FinancialSecretary ? "Financial Secretary" 
            : account.role
          } <sup>√</sup></div>
        </a>
        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Overview</div>
        <li className={isPathActive(`/dashboard`) ? "nav-item active" : "nav-item"}>
          <Link className="nav-link" to={"/dashboard"}>
            <i className="fa fa-tachometer"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <li className={isPathActive(`/cooperators`) ? "nav-item active" : "nav-item"}>
          <div className="nav-link collapsed" onClick={ () => toggleMenuState('cooperatorsOpen') } data-toggle="collapse">
            <i className="fa fa-users"></i>
            <span>Membership</span>
          </div>
          <Collapse in={password.cooperatorsOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner">
                <h6 className="collapse-header">Section 1:</h6>
                <Link className="collapse-item" to={"/cooperators"}>Cooperators</Link>
                <Link className="collapse-item" to={"/cooperators/add"}>Add Cooperator</Link>
                <Link className="collapse-item" to={"/cooperators/contribution-enumeration"}>Enumeration</Link>
              </div>
            </div>
          </Collapse>
        </li>

        <li className={isPathActive(`/loans`) ? "nav-item active" : "nav-item"}>
          <div className="nav-link collapsed position-relative" onClick={ () => toggleMenuState('loansOpen') } data-toggle="collapse">
            <i className="fa fa-credit-card"></i>
            <span>Loans</span>
            {showSidebar && loanPending > 0 ? (
              <span className="badge m-0 bg-danger position-absolute top-25 end-75 f-12 fw-light">{loanPending}</span>
            ) : null}
            {!showSidebar && loanPending > 0 ? (
              <span className="badge m-0 bg-danger pull-right f-12 fw-light">{loanPending}</span>
            ) : null}
          </div>
          <Collapse in={password.loansOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner">
                <h6 className="collapse-header">Section 1:</h6>
                <Link className="collapse-item" to={"/loans"}>Applications
                  {loanPending > 0 ? (
                    <span className="badge m-0 bg-danger pull-right f-12 fw-light">{loanPending}</span>
                  ) : null}
                </Link>
                <Link className="collapse-item" to={"/loans/enumerations"}>Enumerations</Link>
              </div>
            </div>
          </Collapse>
        </li>

        <li className={isPathActive(`/withdrawals`) ? "nav-item active" : "nav-item"}>
          <div className="nav-link collapsed position-relative" onClick={ () => toggleMenuState('withdrawalsOpen') } data-toggle="collapse">
            <i className="fa fa-credit-card"></i>
            <span>Withdrawals</span>
            {showSidebar && withdrawalPending > 0 ? (
              <span className="badge m-0 bg-danger position-absolute top-25 end-75 f-12 fw-light">{withdrawalPending}</span>
            ) : null}
            {!showSidebar && withdrawalPending > 0 ? (
              <span className="badge m-0 bg-danger pull-right f-12 fw-light">{withdrawalPending}</span>
            ) : null}
          </div>
          <Collapse in={password.withdrawalsOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner">
                <h6 className="collapse-header">Section 1:</h6>
                <Link className="collapse-item" to={"/withdrawals"}>Applications
                  {withdrawalPending > 0 ? (
                    <span className="badge m-0 bg-danger pull-right f-12 fw-light">{withdrawalPending}</span>
                  ) : null}
                </Link>
                <Link className="collapse-item" to={"/withdrawals/enumerations"}>Enumerations</Link>
              </div>
            </div>
          </Collapse>
        </li>

        <li className={isPathActive(`/modify-savings`) ? "nav-item active" : "nav-item"}>
          <Link className="nav-link position-relative" to={"/modify-savings"}>
            <i className="fa fa-credit-card"></i>
            <span>Modify Savings</span>
            {showSidebar && modifySavingsPending > 0 ? (
              <span className="badge m-0 bg-danger position-absolute top-25 end-75 f-12 fw-light">{modifySavingsPending}</span>
            ) : null}
            {!showSidebar && modifySavingsPending > 0 ? (
              <span className="badge m-0 bg-danger pull-right f-12 fw-light">{modifySavingsPending}</span>
            ) : null}
          </Link>
        </li>

        <li className={isPathActive(`/management`) ? "nav-item active" : "nav-item"}>
          <div className="nav-link collapsed" onClick={ () => toggleMenuState('managementOpen') } data-toggle="collapse">
            <i className="fa fa-cog"></i>
            <span>Management</span>
          </div>
          <Collapse in={password.managementOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner">
                <h6 className="collapse-header">Section 1:</h6>
                <Link className="collapse-item" to={"/management/cashflow"}>Cashflow Plan</Link>
                <Link className="collapse-item" to={"/management/commodities"}>Commodities</Link>
                <Link className="collapse-item" to={"/management/add-commodity"}>Add Commodity</Link>
              </div>
            </div>
          </Collapse>
        </li>

        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Application Preference</div>
        <li className={isPathActive(`/settings`) ? "nav-item active" : "nav-item"}>
          <Link className="nav-link" to={"/settings"}>
            <i className="fa fa-cogs"></i>
            <span>Account Settings</span>
          </Link>
        </li>
        
        {/* <li className={isPathActive(`/loans`) ? "nav-item active" : "nav-item"}>
          <div className="nav-link collapsed" onClick={ () => toggleMenuState('loansOpen') } data-toggle="collapse">
            <i className="fa fa-handshake-o"></i>
            <span>Loan Applications</span>
          </div>
          <Collapse in={password.loansOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner">
                <h6 className="collapse-header">Section 1:</h6>
                <Link className="collapse-item" to={"/loans"}>Regular&nbsp;Loan</Link>
                <h6 className="collapse-header">Section 2:</h6>
                <Link className="collapse-item" to={"/loans/commodities"}>Commodities</Link>
                <Link className="collapse-item" to={"/loans/add-commodity"}>New Commodity</Link>
              </div>
            </div>
          </Collapse>
        </li> */}

        {/* <hr className="sidebar-divider" /> */}
        

        {/* <div className="sidebar-heading">Accounts</div> */}
        {/* <hr className="sidebar-divider my-" /> */}

        {/* <li className={isPathActive(`/loan`) ? "nav-item active" : "nav-item"}>
          <div className="nav-link collapsed" onClick={ () => toggleMenuState('loanOpen') } data-toggle="collapse">
            <i className="fa fa-cog"></i>
            <span>Components</span>
          </div>
          <Collapse in={password.loanOpen}>
            <div>
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Custom Components:</h6>
                <Link className="collapse-item" to={"/loan"}>Buttons</Link>
                <Link className="collapse-item" to={"/loan/new"}>Cards</Link>
              </div>
            </div>
          </Collapse>
        </li> */}

        {/* <li className={isPathActive(`/app-management`) ? "nav-item active" : "nav-item"}>
          <Link className="nav-link" to={"/app-management"}>
            <i className="fa fa-tachometer"></i>
            <span>App Management</span>
          </Link>
        </li> */}
        
        
          {/* <NavItem title="Dashboard" link="/dashboard" icon="fa-tachometer" external={false} />
          <hr className="sidebar-divider" />

          <div className="sidebar-heading">Accounts</div>
          <CollapsableNavItem eventKey="/cooperators" title="Cooperators" subtitle="Profile Settings:" icon={"fa-user-secret"}>
            <CollapseNavLink title="Cooperators List" link="cooperators" />
            <CollapseNavLink title="Add New Cooperator" link="cooperators/new" />
          </CollapsableNavItem>
          <NavItem title="User Management" link="/dashboard" icon="fa-tachometer" external={false} />

          <hr className="sidebar-divider mt-2" /> */}

          {/* <div className="sidebar-heading">Overview</div>
          <CollapsableNavItem eventKey="/dashboard" title="Dashboard" subtitle="Dashboard Screens:" icon={"fa-folder"}>
            <CollapseNavLink title="Login" link="login" />
            <CollapseNavLink title="Register" link="Register" />
          </CollapsableNavItem>
          <hr className="sidebar-divider" /> */}

        {/* <hr className="sidebar-divider d-none d-md-block" /> */}
        {/* <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle" onClick={() => {
            dispatch({ type: appConstants.ADMIN_SIDEBAR, payload: !showSidebar });
            document.body.classList.toggle("sidebar-icon-only");
          }}></button>
        </div> */}
      </ul>
    </>
  )
}

function getWindowSize() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export { Sidebar }
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { Nav, Dropdown} from 'react-bootstrap';
import { Roles, useAuth } from '_helpers';
// import { RootState } from "_reducers";
// import { dateFormat, currency } from '_utils';

const Header = () => {
  const { auth, logout } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location as any;
  // const { items } = useSelector((state: RootState) => state.asGuarantor);

  useEffect(() => {
    // dispatch(cooperatorActions.getAsGuarantor());
  }, [dispatch]);

  // const unattendedToAsGuarantor = items.filter((item) => (
  //   item.guarantors.map((x) => x.status)
  // )
  // const pendingItems = items.filter((item) => (item.))
  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     // setNotifications(notifications.map(n => ({ ...n, read: true })));
  //   }, 300);
  // };

  // console.log(loading, items, error);

  // const filteredLoanItems = []
  // items.filter((item: { guarantors: any[]; }) => {
  //   return item.guarantors.some((guarantor) => guarantor.gId === id && guarantor.status === "Waiting");
  // });

  return (
    <header className="profile-header">
      <div className="header-wrapper">
        <div className="header-wrapper-left">
          <div className="header-inner">
            <h6 className="header-title-text">{state ? state?.title : "My Account"}</h6>
            <span className="text-muted d-none d-md-block">FUO Staff Multipurpose Cooperative Society Ltd</span>
          </div>
        </div>
        <div className="header-wrapper-right">
          <div className="header-inner d-flex align-items-center justify-content-center">
            {/* <Dropdown className="no-arrow notifications-dropdown d-flex mx-auto me-1" >
              <Dropdown.Toggle as={Nav.Link} className="icon-notifications me-1">
                <span className="icon icon-sm">
                  <i className="fa fa-bell bell-shake" />
                  <span className="rounded-circle unread-notifications" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-notification" align={"end"} >
                <ul>
                  {filteredLoanItems.length > 0 && filteredLoanItems.slice(0, 2).map((item: any) => (
                    <Dropdown.Item key={item?.id} className="li" as={Link} to="/account/profile#asguarantor">
                      <div className="icon-box"><i className="fa fa-paypal"></i></div>
                      <div className="header-inner">
                        <span className="name"><strong>Guarantor Request</strong><br />  from {item?.borrower.surname} {item?.borrower.othernames.length > 14 ? item?.borrower.othernames.substring(0, 14) + "..." : item?.borrower.othernames}</span>
                        <span className="time">
                          <span><i className="fa fa-clock-o"></i></span> {dateFormat(item?.updatedAt, {
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                          })}
                        </span>
                      </div>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item className="li">
                    <div className="icon-box"><i className="fa fa-paypal"></i></div>
                    <div className="header-inner">
                      <span className="name"><strong>New message</strong> from Joshua</span>
                      <span className="time">
                        <span><i className="fa fa-clock-o"></i></span> 13 minutes ago
                      </span>
                    </div>
                  </Dropdown.Item>
                </ul>
                <Dropdown.Divider/>
                <Link to="/" className="veiw-all text-center text-primary py-1">
                  View all
                </Link>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown className="profile-dropdown no-arrow">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="image-box rounded-circle border ms-2">
                  <img src={auth?.cooperatorInfo ? auth?.cooperatorInfo.photo.url : process.env.PUBLIC_URL + "/assets/images/main/user.png"} alt="" className="" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-dropdown" align="end">
                {/* <Dropdown.Item className="profile-avatar">
                  <div className="d-flex align-items-start">
                    <div className="d-block">
                      <h6 className="m-0 text-capitalize fw-bold">{auth?.surname} {auth?.othernames && auth.othernames.length > 14 ? auth?.othernames.substring(0, 14) + "..." : auth?.othernames}</h6>
                      <small className="text-primary"><span className="">Monthly Savings:&nbsp;&nbsp;</span>{auth?.monthlySavings ? currency.format(auth.monthlySavings) : null}</small>
                    </div>
                  </div>
                </Dropdown.Item> */}
                {/* <hr className="sidebar-divided my-1" /> */}
                {auth?.role !== Roles.Cooperator && (
                  <Dropdown.Item as={Link} to="/dashboard">
                    <i className="fa fa-first-order me-2" /> Executive Dashboard
                  </Dropdown.Item>
                )}
                <Dropdown.Item as={Link} to="/profile-settings/change-savings">
                  <i className="fa fa-assistive-listening-systems me-2" /> Change Savings
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/withdraw/history">
                  <i className="fa fa-money me-2" /> Withdrawal Records
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/loan/history">
                  <i className="fa fa-handshake-o me-2" /> Track Loans
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/profile-settings">
                  <i className="fa fa-cogs me-2" /> Profile Settings
                </Dropdown.Item>
                
                
                {/* <Dropdown.Item as={Link} to="/as-guarantor">
                  <i className="fa fa-shield me-2" /> As Guarantor
                </Dropdown.Item> */}
                {/* <Dropdown.Item href="/customer#/orders">
                  <i className="fa fa-first-order me-2" /> Track Orders
                </Dropdown.Item>
                <Dropdown.Item href="/customer#/message">
                  <i className="fa fa-envelope me-2" /> Messages <span>1</span>
                </Dropdown.Item>
                <Dropdown.Item>
                  <i className="fa fa-cogs me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Divider /> */}
                <Dropdown.Item className="text-danger" onClick={logout}>
                  <i className="fa fa-sign-out me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  )
}

export { Header }
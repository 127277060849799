import React, { useState } from 'react';
import { Outlet } from 'react-router-dom'
// import { HomeSidebar } from './sidebar';
import { HomeHeader } from './header';

const HomeLayout: React.FC = () => {
  const [show, setShow] = useState(false);
  const onCollapse = () => setShow(!show);

  return (
    <>
      <HomeHeader mdShow={false} show={show} onCollapse={onCollapse} />
      {/* <HomeSidebar show={show} onCollapse={onCollapse} /> */}
      <Outlet />
    </>
  )
}

export { HomeLayout }
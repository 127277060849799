import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useAuth } from '_helpers';

const Sidebar = ({ show, onCollapse }: any) => {
  const { auth, logout } = useAuth()
  const { pathname } = useLocation();
  const showClass = show ? "show" : "";

  const SidebarNavLink = ({ link, title, icon }:any) => {
    const navItemClassName = link === pathname ? "active" : "";
    return (
      <Link to={link} state={{ title }} className={navItemClassName} onClick={onCollapse}>
        <li><div className="nav-inner">
          <div className="icon"><i className={`fa fa-${icon}`}></i></div>
          <div className="nav-link-text">
            <span>{title}</span>
          </div>
        </div></li>
      </Link>
    )
  }

  return (
    <>
      <CSSTransition timeout={300} in={show}>
        <div className="sidebar-transition">
          <div className={`collapse ${showClass} profile-sidebar d-md-block`}>
            <div className="profile-sidebar-inner">
              <div className="sidebar-head">
                <div className="sidebar-close" onClick={onCollapse}>
                  <div className="menu-close-times">
                    <span className="close-times-bar close-bar-top"></span>
                    <span className="close-times-bar close-bar-bottom"></span>
                  </div>
                </div>
                <div className="brand-inner">
                  <Link to="/">
                    <img src={auth?.cooperatorInfo?.photo ? auth?.cooperatorInfo?.photo.url : process.env.PUBLIC_URL + "/assets/images/main/user.png"} alt="" />
                  </Link>
                  <div className="sidebar-brand"> 
                    <h6 className="text-truncate">{auth.surname +" "+ auth?.othernames}</h6>
                    <span className="text-muted text-sm">{auth?.phone}</span>
                  </div>
                </div>
                <hr className="sidebar-divided mt-3 mb-0" />
              </div>

              <div className="sidebar-nav-body">
                <ul className="sidebar-nav">
                  <SidebarNavLink link="/account" title="Dashboard" icon="tachometer" />
                  <SidebarNavLink link="/loan" title="Loan Application" icon="handshake-o" />
                  <SidebarNavLink link="/withdraw" title="Withdraw" icon="money" />
                  <SidebarNavLink link="/commodity-loan" title="Commodity Loan" icon="shopping-bag" />
                  <SidebarNavLink link="/as-guarantor" title="As Guarantor" icon="user-md" />
                  <SidebarNavLink link="/profile-settings" title="Profile Settings" icon="cogs" />
                </ul>

                <div className="bottom-wrapper">
                  {/* <div className="bottom-up-box">
                    <div className="up-inner">
                      <div className="up-inner-one">
                        <i className="fa fa-star"></i>
                      </div>
                      <div className="up-inner-two">
                        <h6>Need help?</h6>
                        <div className="inner-two-text mt-1">
                          <span>Please check our policies</span>
                        </div>
                        <button type="button" className="doc-btn">Constitution</button>
                      </div>
                    </div>
                  </div> */}
                  <div className="mt-4">
                    <button type="button" className="gradient-btn gradient-btn-danger w-100" onClick={() => logout()}><i className="fa fa-sign-out me-2"></i>Logout</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export { Sidebar }
import React from 'react';
import { Outlet } from 'react-router-dom'
// import { Socket } from 'socket.io-client';
import { Sidebar } from './sidebar';
import { Topbar } from './topbar';
import { useLoanApplications } from 'hooks/useLoanApplications';
import { useWithdrawalApplications } from 'hooks/useWithdrawalApplications';
import { useModifySavings } from 'hooks/useModifySavings';
import { useWhoAmI } from 'hooks/useWhoAmI';

// interface Props{
//   socket: Socket | null
// }

const DashboardLayout: React.FC = () => {
  useWhoAmI();
  const { isSuccess: isSuccess_L, data: loanApplications } = useLoanApplications({ status: "Reviewing" });
  const { isSuccess: isSuccess_W, data: withdrawalApplications } = useWithdrawalApplications({ status: "Pending" });
  const { isSuccess: isSuccess_M, data: modifySavingsPending } = useModifySavings({ status: "Pending" });

  return (
    <div id="wrapper">
      <Sidebar
        loanPending={isSuccess_L && loanApplications.length}
        withdrawalPending={isSuccess_W && withdrawalApplications.length}
        modifySavingsPending={isSuccess_M && modifySavingsPending.length}
      />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid px-3 px-lg-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export { DashboardLayout }
import jwt_decode from "jwt-decode";
import { Roles, history } from "_helpers";
import { useState, useEffect, createContext, ReactNode, Dispatch, SetStateAction } from "react";

type AuthProps = {
  auth: any,
  setAuth: Dispatch<SetStateAction<any>>,
  isloadingAuth: boolean,
  setIsLoadingAuth: Dispatch<SetStateAction<boolean>>,
  login: (account: any) => void;
  logout: () => void;
  update: (account: any) => {}
}

const AuthContext = createContext<AuthProps>({} as AuthProps);

const AuthProvider = (props: { children: ReactNode }) => {
  const [isloadingAuth, setIsLoadingAuth] = useState<boolean>(true);
  const [auth, setAuth] = useState<any>(null);

  useEffect(() => {
    loadAsyncStorage();
  }, []);

  const loadAsyncStorage = async () => {
    const account = JSON.parse(localStorage.getItem("account") as any);
    if(account) {
      const _account: { sub: string; role: Roles; phone: string } = jwt_decode(account.token);
      setAuth(() => ({
        ...account,
        id: _account.sub,
        role: _account.role,
      }));
    } 
    setTimeout(() => {
      setIsLoadingAuth(false);
    }, 300);
  };

  const login = async (account: any) => {
    setIsLoadingAuth(true);
    if(account) {
      const _account: { sub: string; role: Roles; phone: string } = jwt_decode(account.token);
      setAuth(() => ({
        ...account,
        id: _account.sub,
        role: _account.role,
      }));
      localStorage.setItem("account", JSON.stringify(account)); 
      setTimeout(() => {
        setIsLoadingAuth(false);
        if(_account?.role === Roles.Cooperator) {
          history.push("/account");
        } else {
          history.push("/dashboard");
        }
      }, 300);
    }
  }

  const logout = async () => {
    setIsLoadingAuth(true);
    setAuth(null);
    localStorage.removeItem("account");
    setTimeout(() => {
      setIsLoadingAuth(false);
      history.push("/");
    }, 300);
  }

  const update = async (account: any) => {
    setAuth((prev: any) => ({ 
      ...prev, 
      ...account
    }));
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, isloadingAuth, setIsLoadingAuth, login, logout, update }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider };
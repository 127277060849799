const Preloader = () => {
	return (
		<div className={`idea-preloader`}>
			<div className="idea-preloader-inner">
				<div className="lds-grid"><div /><div /><div /><div /><div /><div /><div /><div /><div /></div>
			</div>
		</div>
	);
}

export default Preloader;
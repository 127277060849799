import { useQuery } from 'react-query';
import { FilterArgsType } from 'types';
import { useAxiosPrivate } from '_helpers/useAxiosPrivate';
import { Roles, useAuth } from '_helpers';

export function useWithdrawalApplications(filterArgs: FilterArgsType) {
  const { auth } = useAuth();
  const { axiosPrivate, axiosErrorHandler } = useAxiosPrivate();

  const { isLoading, isSuccess, data, isError, error, refetch } = useQuery({ 
    queryKey: ["get-withdrawal-applications", filterArgs], 
    queryFn: async ({ queryKey }) => {
      const { data } = await axiosPrivate.put(`/withdrawal/applications/w`, queryKey[1]);
      return data;
    },
    onError(err) {
      axiosErrorHandler(err);
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: [Roles.President, Roles.Secretary, Roles.AssistantSecretary].includes(auth?.role)
  });

  return { isLoading, isSuccess, data, isError, error, refetch }
}